/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

import Image from "next/image";
import Link from "next/link";

const RatingTemplate = () => {
  return (
    <div className="logos-footer text-nowrap">
      <a
        target="_blank"
        rel="nofollow" className="pe-none"
      >
        <img
          src="/assets/icons/nasscom.svg"
        //   width={""}
        //   height={40}
        //   priority={false}
          alt="rating"
        //   className="img-fluid"
        />
      </a>
      <Link
        href="https://clutch.co/profile/cubet"
        target="_blank"
        rel="nofollow"
      >
        <img
          src="/assets/icons/clutch.svg"
        //   width={47}
        //   height={40}
        //   priority={false}
          alt="rating"
        //   className="img-fluid"
        />
      </Link>
      <Link
        href="https://www.goodfirms.co/company/cubet-techno-labs"
        target="_blank"
        rel="nofollow"
      >
        <img
          src="/assets/icons/good-firm.svg"
        //   width={82}
        //   height={40}
        //   priority={false}
          alt="rating"
        //   className="img-fluid"
        />
      </Link>
      
    </div>
  );
};

export default RatingTemplate;
