/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import React, { useContext } from "react";
import Image from "next/image";
import Link from "next/link";
import { Col, Container, Row } from "react-bootstrap";
import Rating from "./Rating";
import dynamic from "next/dynamic";
import { GlobalContext } from "./context/Global";
import NewsletterFormTemplate from "./NewsLetterFormTemplate";
import RatingTemplate from "./RatingTemplate";
import useWindowSize from "@/hooks/useWindowSize";

const FooterTemplate = () => {
  const [width] = useWindowSize();
  const { isCookiePopup, setCookiePopup } = useContext<any>(GlobalContext);
  return (
    <div className="footer-container-new">
      <div>
        <Row className="align-items-center">
          <Col md={5} lg={6} xl={7}>
            <p className="h6 fw-normal mb-0 get-touch">Get in Touch with us</p>
            <h2 className="heading">
              Looking Forward To <br className="d-break-new" /> Connect With
              You!
            </h2>
            <div className="d-flex align-items-center">
              <Link href="/contact-us" className="footer-contact">
                Contact Us
              </Link>
              <Link href="mailto:hello@cubettech.com" className="footer-email">
                Drop an Email
              </Link>
            </div>
          </Col>
          <Col md={7} lg={6} xl={5} className="d-flex ">
            <div className="w-100 mob-subscribe">
              <div className=" mb-sub">
                <h3 className="me-5 me-md-0 me-lg-5 mb-0">
                  Subscribe to our Newsletter
                </h3>
                {/* <Image
                  src="/assets/icons/arrow_right.png"
                  width={46}
                  height={21}
                  priority={false}
                  alt="arrow"
                  className="d-none d-md-block"
                /> */}
              </div>
              <NewsletterFormTemplate />
            </div>
           
          </Col>
          <Col xs={12} className="d-block d-md-none link-mob">
            <div className="d-flex justify-content-between links">
              <div>
                <h3 className="links-heading">Our Services</h3>
                <ul>
                  <li>
                    <Link href="/services/web-app-development">
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/mobile-app-development">
                      App Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/artificial-intelligence-consulting">
                      Al Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/custom-product-engineering">
                      Product Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/enterprise-solution-integration">
                      Enterprise Solution Integration
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="links-heading">Knowledge</h3>
                <ul>
                  <li>
                    <Link href="/resources/blog">Blogs</Link>
                  </li>
                  <li>
                    <Link href="/resources/whitepapers">Whitepapers</Link>
                  </li>
                  <li>
                    <Link href="/resources/case-studies">Case studies</Link>
                  </li>
                  <li>
                    <Link href="/resources/insights">Insights</Link>
                  </li>
                  <li>
                    <Link href="/cubet-career">Careers</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          {/* <Col xs={12} className="d-block d-md-none">
            <div className="hr"></div>
          </Col> */}
        </Row>
      </div>
      <div className="hr d-none d-md-block"></div>
      <div>
        <Row className="justify-content-between">
          <Col md={6} lg={7} className="d-none d-md-block">
            <Image
              src="/assets/icons/cubet.png"
              width={82}
              height={20}
              priority={false}
              alt="cubet"
              className="img-fluid logo-white"
            />
            <div className="mb-3 address-boxes">
              <a
                href="/contact-us#global"
                className="d-flex align-items-center hover-undeline text-nowrap"
              >
                <Image
                  src="/assets/icons/atlanta.svg"
                  width={30}
                  height={30}
                  priority={false}
                  alt="Cubet US Address"
                  className="img-fluid"
                />
                <p className="ms-2 normal-text text-white mb-0 text-nowrap country-names">Atlanta, US</p>
              </a>
              <a
                href="/contact-us#global"
                className="d-flex align-items-center hover-undeline text-nowrap"
              >
                <Image
                  src="/assets/icons/london-flag.svg"
                  width={30}
                  height={30}
                  priority={false}
                  alt="Cubet IN Address"
                  className="img-fluid"
                />
                <p className="ms-2 normal-text text-white mb-0 country-names">London, UK</p>
              </a>
              <a
                href="/contact-us#global"
                className="d-flex align-items-center hover-undeline"
              >
                <Image
                  src="/assets/icons/india-flag.svg"
                  width={30}
                  height={30}
                  priority={false}
                  alt="Cubet IN Address"
                  className="img-fluid"
                />
                <p className="ms-2 normal-text text-white mb-0 country-names">Kochi, India</p>
              </a>
            </div>
           
            
            
           
          </Col>
          
          <Col md={6} lg={4} className="d-none d-md-block">
            <div className="d-flex justify-content-between links">
              <div>
                <h3 className="links-heading">Our Services</h3>
                <ul>
                  <li>
                    <Link href="/services/web-app-development">
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/mobile-app-development">
                      App Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/artificial-intelligence">
                      AI Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/custom-product-engineering">
                      Product Development
                    </Link>
                  </li>
                  <li>
                    <Link href="/services/enterprise-solution-integration">
                      Enterprise Solution Integration
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="links-heading">Knowledge</h3>
                <ul>
                  <li>
                    <Link href="/resources/blog">Blogs</Link>
                  </li>
                  <li>
                    <Link href="/resources/whitepapers">Whitepapers</Link>
                  </li>
                  <li>
                    <Link href="/resources/case-studies">Case studies</Link>
                  </li>
                  <li>
                    <Link href="/resources/insights">Insights</Link>
                  </li>
                  <li>
                    <Link href="/cubet-career">Careers</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={12}>
          <div className="d-flex justify-content-between rating-item align-items-center">
            <div>{width >= 821 ? <Image src="/assets/icons/london.svg" width={  254 } height={100} alt={"london"}/>: <Image src="/assets/icons/london.svg" width={  163  } height={64} alt={"london"}/>} </div>
              <RatingTemplate />
            </div> 
          </Col>
          <div className="hr-border"></div>
          <div className="justify-content-between flex-block">
          <Col  className="p-0 ">
            <div>
            <div className="mt-25-rem-fixed">
              <ul className="d-flex align-items-center footer-text web-footerlink" style={{lineHeight:"100%"}}>
                <li className="mb-0">
                  <Link href="/privacy-policy" className="fw-600 gap-link-p">
                    Privacy Policy
                  </Link>
                  {/* <div className="position-absolute-line"></div> */}
                </li>
                
                <li className="mb-0 position-relative ">
                  <Link href="/cookie-policy" className="fw-600 gap-link">
                    Cookies
                  </Link>
                  <div className="position-absolute-line"></div>
                </li>
                
                <li className="mb-0 cursor-pointer position-relative ">
                  <a
                    onClick={() => setCookiePopup(!isCookiePopup)}
                    className="fw-600 gap-link"
                  >
                    Cookie Settings
                  </a>
                  <div className="position-absolute-line"></div>
                </li>
                
                <li className="mb-0 position-relative ">
                  <Link href="/terms-conditions" className="fw-600 gap-link">
                    Terms and Conditions
                  </Link>
                  <div className="position-absolute-line"></div>
                </li>
              </ul>
            </div>
           
            </div>
           
          </Col>
          {/* <Col xs={12} className="d-block d-md-none">
            
        
            <Col xs={12} className="d-block d-md-none mt-4">
              <div className="mob-privacy footer-text">
                <div className="d-flex flex-row align-items-start">
                  <Link href="/privacy-policy">Privacy Policy</Link>
                  
                  <Link href="/cookie-policy">Cookies</Link>
                  <a
                    className="ms-0 cursor-pointer"
                    onClick={() => setCookiePopup(!isCookiePopup)}
                  >
                    Cookie Settings
                  </a>
                  <Link href="/terms-conditions">Terms & Conditions</Link>
                </div>
               
              </div>
            </Col>
          </Col> */}
          <Col  className="p-0 ">
            <p className="mt-25-rem-copy-fixed  fs-12 center-right ">
              &copy; {`${new Date().getFullYear()} Cubet. All Rights Reserved.`}
            </p>
            </Col>
            </div>
        </Row>
      </div>
    </div>
  );
};

export default FooterTemplate;
