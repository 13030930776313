import React, { ReactNode, useContext, useEffect, useState } from "react";
import Head from "next/head";
import { SEOProps } from "@/utils/types";
import useWindowScroll from "@/hooks/useWindowScroll";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { GlobalContext } from "./context/Global";
import Cookies from "js-cookie";
import { functionalCookie } from "@/utils/helpers";
import FooterTemplate from "./FooterTemplate";

const CookieModal = dynamic(() => import("./CookieModal"));
const SEO = dynamic(() => import("./SEO"));
const Footer = dynamic(() => import("./Footer"));
const Menu = dynamic(() => import("./Menu"));
const Banner = dynamic(() => import("./Banner"));
const CookieBanner = dynamic(() => import("./CookieBanner"));
const NeedHelpModal = dynamic(() => import("./NeedHelpModal"));

interface LayoutProps extends SEOProps {
  children: ReactNode;
  menuType?: string;
  footerType?: string;
  banner?: boolean;
  bannerClass?: string;
  bannerPage?: string;
  notFixedHeader?: boolean;
  absolute?: boolean;
  ribbonHide?: boolean;
}

const Layout = ({
  children,
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  menuType = "light",
  footerType,
  banner,
  bannerClass,
  bannerPage,notFixedHeader, absolute,ribbonHide
}: LayoutProps) => {
  const router = useRouter();
  const scroll = useWindowScroll();
  const [helpModal, setHelpModal] = useState(false);
  const { helpModalSave, setHelpModalSave } = useContext<any>(GlobalContext);
  const { isCookiePopup, setCookiePopup } = useContext<any>(GlobalContext);
  const [cookie, setCookie] = useState(false);
  const [isFunctional, setIsFunctional] = useState(false);
  const [isMarketing, setIsMarketing] = useState(false);
  const [expand, setExpand] = useState(null);


  useEffect(() => {
    setTimeout(() => {
      setHelpModal(true);
    }, 200000);
  }, []);

  const handleHelpModal = () => {
    setHelpModalSave(true);
    setHelpModal(false);
  };

  const footerClassname = (isFooter:boolean,scrollPostion:number) => {
    if(isFooter){
      return `${bannerClass} banner-slide`
    }
    else if(scrollPostion > 700) {
      return `${bannerClass} banner-slide`
    }
    return `${bannerClass} banner-opacity`

  }
  const setFuntionalCookie = () => {
    functionalCookie?.map((item: any) => Cookies.set(item, "1"));

    setIsFunctional(true);
  };
  const PixelScript = () => {
    const script = document.createElement("script");
    script.id = "facebook-pixel";
    script.async = true;
    script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '792832585465616');
          fbq('track', 'PageView');
        `;

    // Create noscript element
    const noscript = document.createElement("noscript");
    const noscriptImg = document.createElement("img");
    noscript.id = "facebook-pixel1.1";
    noscriptImg.height = 1;
    noscriptImg.width = 1;
    noscriptImg.style.display = "none";
    noscriptImg.src =
      "https://www.facebook.com/tr?id=792832585465616&ev=PageView&noscript=1";
    noscriptImg.alt = "Facebook Pixel";
    noscript.appendChild(noscriptImg);

    // Append script and noscript elements to the head
    document.head.appendChild(script);
    document.head.appendChild(noscript);
  };
  const LinkedInScript = () => {
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.id = "linkedinId1";
    script1.innerHTML = `
  _linkedin_partner_id = "5088548";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
`;

    // Create the second script element
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.id = "linkedinId2";
    script2.innerHTML = `
  (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);
`;

    // Create the noscript element

    const noscript = document.createElement("noscript");
    const noscriptImg = document.createElement("img");
    noscript.id = "linkedin2.1";
    noscriptImg.height = 1;
    noscriptImg.width = 1;
    noscriptImg.style.display = "none";
    noscriptImg.src =
      "https://px.ads.linkedin.com/collect/?pid=5088548&fmt=gif";
    noscriptImg.alt = "";
    noscript.appendChild(noscriptImg);
    document.head.appendChild(script1);
    document.head.appendChild(script2);
    document.head.appendChild(noscript);
  };
  const LinkedInLeadFormTracking = () => {
    // Create the first script element
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.id = "LinkedInLead1";
    script1.innerHTML = `
  _linkedin_partner_id = "5088548";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
`;

    // Create the second script element
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.id = "LinkedInLead2";
    script2.innerHTML = `
  (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);
`;

    // Create the noscript element
    const noscript = document.createElement("noscript");
    const noscriptImg = document.createElement("img");
    noscript.id = "LinkedInLead2.1";
    noscriptImg.height = 1;
    noscriptImg.width = 1;
    noscriptImg.style.display = "none";
    noscriptImg.src =
      "https://px.ads.linkedin.com/collect/?pid=5088548&fmt=gif";
    noscriptImg.alt = "";
    noscript.appendChild(noscriptImg);
    // Append script elements and noscript element to the head of the document
    document.head.appendChild(script1);
    document.head.appendChild(script2);
    document.head.appendChild(noscript);
  };
  const GoogleAds = () => {
    // Create the first script element
    const script1 = document.createElement("script");
    script1.async = true;
    script1.id = "googleAds";
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-482825168";

    // Create the second script element
    const script2 = document.createElement("script");
    script2.id = "googleAds2";
    script2.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-482825168');
`;

    // Append script elements to the head of the document
    document.head.appendChild(script1);
    document.head.appendChild(script2);
  };
  const GistChat = () => {
    const gistScript = document.createElement("script");
    gistScript.id = "gistchat";
    gistScript.innerHTML = `
    (function(d, h, w) {
      var gist = w.gist || (w.gist = []);
      gist.methods = gist.methods || ['trackPageView', 'identify', 'track', 'setAppId'];

      gist.factory = function(t) {
        return function() {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          gist.push(e);
          return gist;
        };
      };

      for (var i = 0; i < gist.methods.length; i++) {
        var c = gist.methods[i];
        gist[c] = gist[c] || gist.factory(c);
      }

      var s = d.createElement('script');
      var e = d.getElementsByTagName(h)[0];
      s.src = "https://widget.getgist.com";
      s.async = true;
      e.appendChild(s);

      s.addEventListener('load', function(e) {
        // You can add any necessary logic here
      }, false);

      gist.setAppId("xtcm3v7n");
      gist.trackPageView();
    })(document, 'head', window);
  
  
`;
    // Append the Gist script element to the head of the document
    document.head.appendChild(gistScript);
  };

  const MetaPixel = () => {
    // Create the script element for Facebook Pixel
    const facebookPixelScript = document.createElement("script");
    facebookPixelScript.id = "metaPixel";
    facebookPixelScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '792832585465616');
    fbq('track', 'PageView');
`;

    // Create the noscript element for Facebook Pixel
    const noscript = document.createElement("noscript");
    const noscriptImg = document.createElement("img");
    noscript.id = "metapixel1";
    noscriptImg.height = 1;
    noscriptImg.width = 1;
    noscriptImg.style.display = "none";
    noscriptImg.src =
      "https://www.facebook.com/tr?id=792832585465616&ev=PageView&noscript=1";
    noscriptImg.alt = "";
    noscript.appendChild(noscriptImg);
    // Append Facebook Pixel script and noscript elements to the head of the document
    document.head.appendChild(facebookPixelScript);
    document.head.appendChild(noscript);
  };
  const setMarketingCookies = () => {
    // Create Facebook Pixel script element
    // PixelScript();
    // LinkedInScript();
    // LinkedInLeadFormTracking();
    // GoogleAds();
    // GistChat();
    // MetaPixel();
    setIsMarketing(true);
  };
  const setCookieFn = (val: any) => {
    if (val === "All") {
      setFuntionalCookie();
      setMarketingCookies();
    }
  };
  const removeCookieFn = (val: any) => {
    if (val === "All") {
      removeFunctionalCookie();
      removeMarketingCookie();
      setIsMarketing(false);
      setIsFunctional(false);
    }
  };
  const handleAccept = () => {
    setCookieFn("All");
    Cookies.set("cookieconsent", "Accept");
    setCookie(false);
  };
  const handlePreference = () => {
    setCookiePopup(!isCookiePopup);
  };

  const handleReject = () => {
    // Cookies.remove;
    removeCookieFn("All");
    Cookies.set("cookieconsent", "Reject");
    setCookie(false);
  };
  const removeFunctionalCookie = () => {
    functionalCookie?.map((item: any) => Cookies.remove(item));
  };
  const removeMarketingCookie = () => {
    const marketingScriptId = [
      "facebook-pixel",
      "facebook-pixel1.1",
      "linkedinId1",
      "linkedinId2",
      "linkedin2.1",
      "LinkedInLead1",
      "LinkedInLead2",
      "LinkedInLead2.1",
      "googleAds",
      "googleAds2",
      "gistchat",
      "metaPixel",
      "metapixel1",
    ];
    marketingScriptId?.map((item) => {
      const scriptToRemove = document.getElementById(item);
      if (scriptToRemove) {
        scriptToRemove.parentNode?.removeChild(scriptToRemove);
        console.log("Script removed");
      } else {
        console.log("Script not found");
      }
    });
  };
  const submitHandler = (val:any) => {
    if(val ==='selected'){
      if (isFunctional && isMarketing) {
        handleAccept();
      } else if (isFunctional && !isMarketing) {
        setFuntionalCookie();
        removeMarketingCookie();
        const preferences = { isFunctional: "1", isMarketing: "0" };
        const preferencesString = JSON.stringify(preferences);
        Cookies.set("cookieconsent", "Preferences");
        Cookies.set("Preferences", preferencesString);
        // remove ismarketing code need to add .
      } else if (!isFunctional && isMarketing) {
        setMarketingCookies();
        removeFunctionalCookie();
        const preferences = { isFunctional: "0", isMarketing: "1" };
        const preferencesString = JSON.stringify(preferences);
        Cookies.set("cookieconsent", "Preferences");
        Cookies.set("Preferences", preferencesString);
      } else {
        removeMarketingCookie();
        removeFunctionalCookie();
      }
    }else if(val ==='All'){
      handleAccept();
    }else if(val ==='Deny'){
      handleReject();
    }    debugger


    setCookiePopup(false);
    setCookie(false);
  };
  const cookieBanner = () => {
    const getCookieconsent = Cookies.get("cookieconsent");
    if (getCookieconsent) {
      if (
        getCookieconsent === "Accept" ||
        getCookieconsent === "Reject" ||
        getCookieconsent === "Preferences"
      ) {
        setCookie(false);
      }
      if (getCookieconsent === "Accept") {
        handleAccept();
      } else if (getCookieconsent === "Reject") {
        handleReject();
      } else if (getCookieconsent === "Preferences") {
        const preferencesCookieValue: any = Cookies.get("Preferences");
        const preferences = JSON.parse(preferencesCookieValue);
        if (
          preferences?.isFunctional === "0" &&
          preferences?.isMarketing === "1"
        ) {
          setMarketingCookies();
          removeFunctionalCookie();
        }
        if (
          preferences?.isFunctional === "1" &&
          preferences?.isMarketing === "0"
        ) {
          setFuntionalCookie();
          removeMarketingCookie();
        }
      }
    } else {
      setCookie(true);
    }
  };
  useEffect(() => {
    // cookieBanner();
  }, []);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <SEO
        title={title}
        description={description}
        ogTitle={ogTitle}
        ogDescription={ogDescription}
        ogImage={ogImage}
        canonical={`${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`}
      />
      <header>
        <Menu menuType={menuType} notFixedHeader={notFixedHeader} absolute={absolute} />
      </header>
      <main>{children}</main>
      <footer className={footerType === "banner" ? "footer-banner" : ""}>
        {/* <Footer /> */}
        <FooterTemplate />

      </footer>
      {banner && !cookie && !ribbonHide && (
        <Banner
          page={bannerPage}
          scrollPos={scroll}
          setExpand={setExpand}
          expand={expand}
          className={
            // footerClassname(expand,scroll)

           
            // scroll > 700 && scroll < 2500
            //   ? `${bannerClass} banner-opacity`
            //   : 
              (expand === true ?  
              `${bannerClass} banner-slide d-none` : (scroll > 702&&expand==null) 
              ? `${bannerClass} banner-slide d-none`:`${bannerClass} d-block banner-opacity`)
          }
          // className={`${bannerClass} banner-opacity`}
        />
      )}
      {/* {cookie && <CookieManager  cookie={cookie} setCookie={setCookie}/>} */}
      {/* {cookie && (
        <CookieBanner
          className={`${bannerClass} banner-opacity`}
          setCookie={setCookie}
          handleAccept={handleAccept}
          handleReject={handleReject}
          handlePreference={handlePreference}
        />
      )} */}
      {/* {!helpModalSave && !isCookiePopup && (
        <NeedHelpModal visible={helpModal} setVisible={handleHelpModal} />
      )} */}
      {isCookiePopup && (
        <CookieModal
          show={isCookiePopup}
          onHide={() => setCookiePopup(false)}
          isFunctional={isFunctional}
          setIsFunctional={setIsFunctional}
          isMarketing={isMarketing}
          setIsMarketing={setIsMarketing}
          submitHandler={submitHandler}
        />
      )}
    </>
  );
};

export default Layout;
